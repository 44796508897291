@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400,500,600,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: "Poppins", sans-serif;
    background: #ffffff;
    overflow-x: hidden;
    height: 100% !important;
}

a {
    text-decoration: none;
}

.text-logo {
    font-size: 30px;
    color: #6713e1 !important;
    font-weight: 800 !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: -2px;
}

.text-logo img {
    width: 30px;
}

.mt-lc {
    margin-left: 300px;
}

.nav-space {
    margin-right: 50px;
}

.navbar {
    background-color: #ffffff;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
}

.navbar-nav a:hover {
    color: #6713e1 !important;
}

.bt-border {
    border-bottom: 3px solid rgba(0, 0, 0, 0.25);
}

.text-#6713E1 {
    color: #6713e1;
    font-weight: bold;
}
.google {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: none;
    outline: none;
    padding: 0.4rem;
    border-radius: 10px;
    width: 100% !important;
}
.google2 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: none;
    outline: none;
    padding: 0.4rem 1rem;
    border-radius: 10px;
}

.google-icon {
    color: #6713e1;
    font-size: 2rem;
}
.ios-icon {
    color: #6713e1;
    font-size: 2.5rem;
}

.ios {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: none;
    outline: none;
    padding: 0.45rem;
    border-radius: 10px;
    width: 100% !important;
}
.ios2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: none;
    outline: none;
    padding: 0.35rem 1.25rem;
    border-radius: 10px;
}
.ios3 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    border: none;
    outline: none;
    padding: 0.3rem 1.25rem;
    border-radius: 10px;
}
.ios-text p {
    font-weight: lighter;
    color: rgb(47, 47, 47);
}
.ios-text h4 {
    margin-top: -1rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: 1.1rem;
}
@media (max-width: 780px) {
    .mt-lc {
        margin-left: 2px !important;
    }
    .nav-space {
        margin-right: 0 !important;
    }
    .btn-fill {
        width: 100% !important;
    }
    .navbar-nav a:hover {
        color: #6713E1 !important;
        font-weight: bold;
    }
    .sidebar {
        width: 100%;
    }
    .row-pd {
        margin-top: 5rem !important;
        padding: 15px !important;
    }
    .app-img img {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        position: static !important;
        margin-top: 40px !important;
        width: 70%!important;
    }
    .mt-6,
    .mt-5,
    .mt-7,
    .mt-4 {
        margin-top: 25px !important;
    }
    .h1-text {
        font-size: 28px !important;
        line-height: 42px !important;
    }
    .app-icon {
        display: block !important;
        margin-left: 5.7rem !important;
    }
    .app-icon2 {
        display: block !important;
        margin-left: 6.5rem !important;
    }
    .google {
        padding: 0.4rem 0.8rem;
    }
    .google-icon {
        font-size: 1.5rem;
    }
    
    .ios {
        padding: 0.3rem 1rem;
    }
    .ios3 {
        padding: 0.3rem 1.1rem;
    }
    .ios-icon {
        font-size: 2rem;
    }
    .ios-text p {
        font-size: 0.8rem;
        padding-left: 0.5rem;
    }
    .ios-text h4 {
        font-size: 0.9rem;
    }
    .pd-t11 {
        padding-top: 30px !important;
    }
    .pd-t2 {
        padding-top: 40px !important;
    }
    .p-6,
    .p-7 {
        padding: 0 20px !important;
    }
    .step2,
    .step3 {
        margin-top: 15px !important;
    }
    .bg-subscribe {
        position: static !important;
        margin-bottom: 10px !important;
        padding: 10px 15px 15px 60px !important;
    }
    .pd-l {
        padding-left: 0 !important;
    }
    .pd-b {
        padding-bottom: 15px;
    }
    .p-nav {
        padding: 25px !important;
    }
    .bg-appia {
        height: 491px !important;
    }
    .modal-fullscreen-md-down {
        height: 576px;
        margin-top: 50px;
        border-radius: 2px;
    }
    .success button {
        height: 50px;
        margin-top: 2px !important;
    }
    .success p {
        margin-top: 2px !important;
    }
    .bg-d {
        height: 480px !important;
    }
    .v-card {
        height: 100% !important;
        width: 100% !important;
    }
    .bill {
        height: 100% !important;
        width: 100% !important;
    }
    .e-account {
        height: 100% !important;
        width: 100% !important;
    }
 xt {
        font-weight: 30px !important;
    }
    .text-logo {
        font-size: 22px !important;
    }
    .fs-big {
        font-size: 25pt !important;
    }
   
    .security-check {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        margin-top: 2.5rem;
    }
    .text-text {
        text-align: center;
    }
    .check-icon {
        margin-right: 1.5rem;
        font-size: 1.5rem;
        background-color: #dcbcf5;
        padding: 0.5rem;
        border-radius: 30px;
        margin-top: 1rem;
    }
    .check-text h1 {
        line-height: 32px !important;
        font-size: 1.2rem !important;
    }
    .check-text p {
        font-size: 0.9rem !important;
    }
    .shield-img img {
        display: none !important;
    }
}

.btn-fill {
    background: linear-gradient(180deg, #8a1ae2 0%, #2e5fbf 100%);
    border-radius: 5px;
    color: #ffffff;
}

.btn-fill:hover {
    background: linear-gradient(180deg, #2e5fbf 0%, #8a1ae2 100%);
    color: #ffffff !important;
}

.row-pd {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    padding: 50px 100px;
    background: linear-gradient(180deg, #8e2de2 0%, #9b40e0 100%);
    height: 900px;
}

.security {
    margin-left: auto;
    margin-right: auto;
    padding: 50px 100px;
    background: linear-gradient(180deg, #8e2de2 0%, #9b40e0 100%);
    height: 100%;
    color: #ffffff;
}

.security-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.security-check {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: 2.5rem;
}

.check-icon {
    margin-right: 1.5rem;
    font-size: 1.5rem;
    background-color: #dcbcf5;
    padding: 0.5rem;
    border-radius: 30px;
    margin-top: 1rem;
}

.check-text h1 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 62px;
    font-weight: 800;
    color: #ffffff !important;
}

.check-text p {
    font-size: 1.2rem;
}

.shield-img img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    ;
    width: 700px;
}

.clients {
    border-top: 0.5px solid #979797;
    border-bottom: 0.5px solid #979797;
}

.mt-R {
    margin-right: 20px;
}
.app-icon {
    display: -webkit-flex;
    display: flex;
}
.app-icon2 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.app-icon  .google-app {
    margin-right: 2rem;
}
.app-icon2  .google-app {
    margin-right: 2rem;
}
.app-icon3 {
    display: block !important;
    /* margin-left: 4.5rem !important;
    margin-right: auto !important; */
}

.app-img img {
    margin-top: 100px;
    width: 500px;
    -webkit-animation: mover 1s linear infinite alternate;
    animation: mover 1s linear infinite alternate;
}

.app-img img {
    -webkit-animation: mover 1s linear infinite alternate;
    animation: mover 1s linear infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}

.mt-6 {
    margin-top: 90px;
}

.mt-7 {
    margin-top: 180px;
}

.h1-text {
    font-family: "Poppins", sans-serif;
    font-size: 48px;
    line-height: 62px;
    font-weight: 800;
    color: #ffffff !important;
}

.h1-logo {
    font-family: "Poppins", sans-serif;
    font-size: 38px;
    line-height: 52px;
    font-weight: 800;
    color: #cf98fa !important;
}

.text-white {
    color: #ffffff;
}

.bg-white {
    background: white;
}

.pd-t {
    padding-top: 30px;
}

.pd-t2 {
    padding-top: 100px;
}

.pd-t1 {
    padding-top: 30px;
}

.p-6 {
    padding: 0 60px;
}

.p-7 {
    padding: 0 80px;
}

.pd-l {
    padding-left: 80px;
}

.pd-t11 {
    padding-top: 70px;
}


.step2 {
    margin-top: 110px;
}

.step3 {
    margin-top: 185px;
}


.steps {
    margin-top: 4rem;
}

.j-text {
    color: #f77321;
    font-weight: bold;
}

.text-purple {
    color: #b15bf3 !important;
}

.text-light-purple {
    color: #e2d0f3 !important;
}

.bttn-submit {
    background: #8a1ae2 !important;
}

.btn-fill-#6713E1 {
    padding: 10px 30px;
    background: linear-gradient(180deg, #8a1ae2 0%, #2e5fbf 100%);
    border-radius: 0 !important;
}

.btn-#6713E1 {
    background: #8221df;
    border-radius: 8px;
    padding: 10px 30px;
}

.bg-purple {
    background: #8221df !important;
}

.btn-white {
    background: white;
    border-radius: 0 !important;
    padding: 10px 30px;
}

.bg-d {
    background: url(/static/media/bg-download.b547e33f.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1.5rem;
}

.c-card {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 30px;
}

.dash {
    background: #979797;
    height: 4px;
    width: 35px;
    margin-right: 10px;
    border-radius: 10px;
}

.dash-active {
    background: linear-gradient(180deg, #8a1ae2 0%, #2e5fbf 100%);
}

.bg-subscribe {
    background: url(/static/media/subscribe.3654f6b3.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2.5rem 4rem;
    border-radius: 12px;
    position: relative;
    margin-bottom: -140px;
    /* height: 100% !important; */
    width: 100% !important;
}

.form-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.launch h4 {
    color: white;
}

.form-pd {
    padding: 20px 0;
    padding-left: 50px;
    width: 100%;
    border: none;
}

.form-icon {
    margin-right: -40px;
    z-index: 2;
    display: block;
    pointer-events: none;
    color: #aaa;
}


.img-d img {
    border-radius: 6px;
}

.q-link li {
    list-style-type: none;
    margin-top: 18px;
}

.q-link a {
    color: #8c97ac;
}

.text-footer {
    color: #8c97ac;
}

.t-icon{
    justify-self: right;
}

.t-icon a {
    margin-right: 10px;
    font-size: 1.2rem;
    border-radius: 30px;
}

hr {
    border: 1px solid #8c97ac;
}

.s-form .form-control { 
    height: 72px;
    border: none !important;
    border-radius: 0rem !important;
}

.btn-create {
    background: linear-gradient(94.76deg, #8e2de2 7.31%, #4a00e0 107.19%);
    border-radius: 24px;
    padding: 8px 30px;
    font-weight: bold;
}

.img-round {
    border-radius: 6px;
}

.small {
    font-size: 10.5pt !important;
}

.bg-appia {
    background: url(/static/media/bg-appia.5859e643.png);
    background-repeat: no-repeat;
    height: 791px;
    background-size: contain;
}

.fs-big {
    font-size: 35pt;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
}

.success p {
    line-height: 33px;
}

.success h1 {
    font-weight: bold;
    font-size: 40px;
    color: #35007c;
}

.btn-big-#6713E1 {
    background: linear-gradient(180deg, #8e2de2 0%, #4a00e0 100%);
    border-radius: 8px;
    height: 91px;
    width: 270px;
    font-size: 25px;
    color: #ffffff;
}

.btn-got:hover {
    color: #aad3ee;
}

.modal-round {
    border-radius: 32px !important;
}

.bg-get {
    background: linear-gradient(180deg, #8e2de2 0%, #4a00e0 100%);
    border-radius: 2px;
}

.img-sm {
    width: 65%;
}

.text-dark {
    color: #000000;
}

.p-nav {
    padding: 25px 200px;
    border-bottom: 1px solid #cccccc;
}

.mg {
    margin: 0 10px;
}

.content {
    border-left: 1px solid #cccccc;
}

.side-nav li {
    list-style-type: none;
    margin-top: 15px;
}

.side-nav small {
    margin-left: 15px;
    margin-top: 15px;
    display: block;
}

.side-nav a {
    color: black;
}

.text-black {
    color: black !important;
}

.accordion-item {
    border: none;
}

.accordion-button {
    border: none !important;
    outline: none !important;
}

.accordion-button:active {
    border-bottom: none !important;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
}

.accordion-button:focus {
    border-bottom: none !important;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
}

.container-fluid .contact-bg {
    background: #e6ddf7;
    height: 720px;
}

.message {
    background: #ffffff;
    border-radius: 12px;
    padding: 50px 30px;
    box-shadow: 2px 16px 32px rgb(207, 229, 243);
    margin-top: -440px;
}

.contact {
    margin-left: 60px;
}

.contact .p-text {
    letter-spacing: 7px;
    font-weight: bold;
}

.contact h1 {
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-bottom: 56px;
    margin-top: 30px;
}

.message .pd-4 {
    border-left: 1px solid #cccccc;
    padding: 0 30px;
}

.message .pdt-4 {
    padding-top: 50px;
}

.pd-4 button {
    padding: 8px 50px;
    border-radius: 8px;
}

.map iframe {
    width: 100%;
}

.fa-down {
    font-size: 2rem;
    -webkit-animation: pulse 1s ease infinite;
            animation: pulse 1s ease infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
